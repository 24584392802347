/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/@splidejs/splide@4.1.4/dist/js/splide.min.js
 * - /npm/jquery@3.6.4/dist/jquery.min.js
 * - /npm/is-in-viewport@3.0.4/lib/isInViewport.min.js
 * - /npm/@barba/core@2.9.7/dist/barba.umd.min.js
 * - /npm/jquery-ui-dist@1.13.2/jquery-ui.min.js
 * - /npm/jquery-ui-touch-punch@0.2.3/jquery.ui.touch-punch.min.js
 * - /npm/@splidejs/splide-extension-video@0.8.0/dist/js/splide-extension-video.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
